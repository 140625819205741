<template>
    <div class="project-container">
        <div class="back">
            <el-button type="primary" @click="goToList">返回</el-button>
        </div>
        <el-table
                :data="projectList"
                style="width: 100%; margin-top: 20px; flex: 1;"
                class="project-table"
                stripe
                height="1%"
                size="medium"
                row-key="id"
                default-expand-all
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#F7F7F7',}" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="project_title" label="项目名称" min-width="400px"></el-table-column>
            <el-table-column label="时长" align="center">
                <template slot-scope="scope">
                    {{scope.row.project_duration * 45}}分钟
                </template>
            </el-table-column>
            <el-table-column prop="project_pv" label="浏览量" align="center"></el-table-column>
            <el-table-column label="难度" align="center" width="130px">
                <template slot-scope="scope">
                    <div class="difficulty" v-if="scope.row.project_difficulty === 1">
                        <i class="iconfont">&#xe64c;</i>
                    </div>
                    <div class="difficulty" v-if="scope.row.project_difficulty === 2">
                        <i class="iconfont" v-for="n in scope.row.project_difficulty">&#xe64c;</i>
                    </div>
                    <div class="difficulty" v-if="scope.row.project_difficulty === 3">
                        <i class="iconfont" v-for="n in scope.row.project_difficulty">&#xe64c;</i>
                    </div>
                    <div class="difficulty" v-if="scope.row.project_difficulty === 4">
                        <i class="iconfont" v-for="n in scope.row.project_difficulty">&#xe64c;</i>
                    </div>
                    <div class="difficulty" v-if="scope.row.project_difficulty === 5">
                        <i class="iconfont" v-for="n in scope.row.project_difficulty">&#xe64c;</i>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200px">
                <template slot-scope="scope">
                    <span class="like" @click="dianzan(scope.row)">
                        <i v-if="scope.row.project_live_num.length === 0" class="iconfont">
                            &#xe85c;
                        </i>
                        <i v-if="scope.row.project_live_num.length > 0" class="iconfont" style="color: red;">
                            &#xe85c;
                        </i>
                    </span>
                    <el-button size="small" type="text" @click="goToProjectDetail(scope.row)">查看详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                style="margin-top: 0"
                class="pages-center"
                :current-page="projectPages.currentPageNum"
                :page-size="projectPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="projectPages.total"
                @current-change="projectCurrentChange"
        ></el-pagination>
    </div>
</template>

<script>
    export default {
        name: "Project",
        data() {
            return {
                //项目列表
                projectList: [],
                //分类id
                resource_class_id: Number(this.$route.query.id) || '',
                //分页
                projectPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        mounted() {
            this.getProjectList();
        },
        methods: {
            //获取项目列表
            getProjectList() {
                let param = {
                    resource_class_id: this.resource_class_id
                }
                this.$http.axiosGetBy(this.$api.project_projectList, param, (res) => {
                    if (res.code === 200) {
                        this.projectList = res.data.list;
                        this.projectList.total = res.data.total;
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //点赞
            dianzan(item) {
                this.$http.axiosGetBy(this.$api.project_dianZan, {project_id: item.id}, (res) => {
                    if (res.code === 200) {
                        this.getProjectList();
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //查看项目详情
            goToProjectDetail(item) {
                this.$router.push({
                    path: '/admin/index/projectDetail',
                    query: {
                        id: item.id
                    },
                })
            },
            //分页
            projectCurrentChange(val) {
                this.projectPages.currentPageNum = val;
                this.getProjectList()
            },
            //返回
            goToList() {
                this.$router.push({
                    path: '/admin/projectLibrary/index'
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .project-container {
        display: flex;
        flex-direction: column;
        padding: 20px 70px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background-color: #fff;
        .project-table {
            ::v-deep table {
                ::v-deep td:last-child {
                    .cell {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .like {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
        .difficulty {
            color: #FFD83E;
        }
        .pages-center {
            background-color: #fff;
            padding: 20px 0;
        }
    }
    .back {
        text-align: right;
    }
</style>